<template>
  <div class="view-projects">
    <div class="view-header">
      <h2>Projets</h2>
      <div>
        <button-reload @click="loadProjects(true)" />
        <button @click="$router.push({name: 'ProjectNew'})"
        class="primary"><mdicon name="plus" /> Nouveau projet</button>
      </div>
    </div>
    <div class="list block">
      <table>
        <thead>
          <tr>
            <th>Projet</th>
            <th>Sitemap</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in projects" :key="p.id" @click="open(p.id)">
            <td>{{p.name}}</td>
            <td>{{p.url}}</td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="project" v-for="p in projects" :key="p.id" @click="open(p.id)">
        <div class="shadow round">
          <h3>{{p.name}}</h3>
          <span>{{p.url}}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import ButtonReload from '../components/ButtonReload.vue';

export default {
  components: { ButtonReload },
  computed: {
    ...mapState('projects', [
      'projects',
    ]),
  },
  methods: {
    ...mapActions('projects', [
      'loadProjects',
    ]),
    open(id) {
      this.$router.push({ name: 'Project', params: { id } });
    },
    favicon(url) {
      const u = new URL(url);
      u.pathname = 'favicon.ico';
      return u.toString();
    },
  },
  async mounted() {
    this.loadProjects();
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.project {
  position: relative;
  z-index: 1;
  margin: 1rem;
}
.project>div {
  padding: 2rem;
  background: #fff;
  cursor: pointer;
  transition: all .2s ease;
  min-width: 100px;
  h3 {
    margin: 0;
    @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d 50%));
  }
  span {
    color:#222;
    font-weight: 100;
  }
}

.project::before {
  content: '';
  position: absolute;
  background-image: linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045);
  background-size: 100%;
  border-radius: 1rem;
  z-index: -1;
  width: 100%;
  height: 105%;
  top:-2%;
  transform: scale(1);
  transition: all .2s ease;
  box-shadow: 0 3px 10px rgba(#000, 0);
}

.project:hover::before {
  transition: all .2s ease;
  transform: scale(1.04);
  box-shadow: 0 3px 20px rgba(#000, .3);
  // background: linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045);
  background-size: 300%;
}

</style>
